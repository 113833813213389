import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/web/web/src/templates/default-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "800px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.49999999999999%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABuklEQVQoz01SS27bMBQckRQlUR9LslXJSp3YSNLAaBwEyL6XKNBFL9BtNz1CgW5aoAcoetRpycdYXgyeSDzOmzcjwGyIdCJ0R6jiAm6puiFMR5ieoT+gv7jztSV0TUCvuKCJqAlVEaqUqkv5DsS9QLfy5nxuZDjMwAVxsm8MhJU0IieS/EJ5KTUp4qA63hUEsj2R3xHZQVb38nVcJR0I1bOor2QlVQteCfwQWCLJFiB/RxSPRHZLpCNh1kJsd1TunsDMX7//8NPnL0GpzqalzxMHkldSSyDdRmXRF99sr5kUD4Q58fD4kT9+/uXXb9+p3YEwW1EeNqmjBd6aKnp4TrOIhJOs705E8cxy/ECkTzSrF2btE2EmWdv751X59yGYQbw/m+0P9i3F01uBV68GJtkNYW+I9EqCC/7FFX0NacdghdBFdbM8tDt5mETjQ5q19IQ/wavpo9KYeiBdL4SJaansmsoOVHZDlXYRa+p8pMlnqmymyvchLFPe07g9dT4zSTfxP6w8YRYUpG5i2ezoqi1dNdNVO7rmjkXzwNX4wtX4zKI50rUnlt2J/fCe/Zsju+FIW16L4v8h/QNTffeCiCVIVwAAAABJRU5ErkJggg==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "desktop",
        "title": "desktop",
        "src": "/static/a64a29a37428910d21bcf03964813509/5a190/desktop.png",
        "srcSet": ["/static/a64a29a37428910d21bcf03964813509/772e8/desktop.png 200w", "/static/a64a29a37428910d21bcf03964813509/e17e5/desktop.png 400w", "/static/a64a29a37428910d21bcf03964813509/5a190/desktop.png 800w", "/static/a64a29a37428910d21bcf03964813509/c1b63/desktop.png 1200w", "/static/a64a29a37428910d21bcf03964813509/29007/desktop.png 1600w", "/static/a64a29a37428910d21bcf03964813509/29114/desktop.png 1920w"],
        "sizes": "(max-width: 800px) 100vw, 800px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`➜ `}<strong parentName="p">{`Operating System:`}</strong>{` Arch Linux and Windows`}</p>
    <p>{`➜ `}<strong parentName="p">{`Text Editor:`}</strong>{` JetBrains IDEs (PhpStorm and Datagrip) & VSCode`}</p>
    <p>{`➜ `}<strong parentName="p">{`Version Control:`}</strong>{` Git and GitHub`}</p>
    <p>{`➜ `}<strong parentName="p">{`Web Development Frameworks:`}</strong>{` Laravel, FastAPI, Gatsby, Express.js`}</p>
    <p>{`➜ `}<strong parentName="p">{`Game Development Engine:`}</strong>{` Unreal Engine, considering exploring Godot`}</p>
    <p>{`➜ `}<strong parentName="p">{`Programming Languages:`}</strong>{` Python, JavaScript, PHP, C#`}</p>
    <p>{`➜ `}<strong parentName="p">{`Database:`}</strong>{` PostgreSQL, MySQL`}</p>
    <p>{`➜ `}<strong parentName="p">{`Web Servers:`}</strong>{` Nginx, Apache`}</p>
    <p>{`➜ `}<strong parentName="p">{`DevOps Tools:`}</strong>{` Docker, Kubernetes`}</p>
    <p>{`➜ `}<strong parentName="p">{`Testing Frameworks:`}</strong>{` PyTest, Pest`}</p>
    <p>{`➜ `}<strong parentName="p">{`Package Manager:`}</strong>{` Pacman (for Arch Linux) or npm (for JavaScript), composer (for PHP)`}</p>
    <p>{`➜ `}<strong parentName="p">{`Collaboration Tools:`}</strong>{` Slack, Notion, Jira`}</p>
    <p>{`➜ `}<strong parentName="p">{`Documentation:`}</strong>{` Markdown`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      